<template>
  <div id="organisation-details-users">
    <b-modal
      v-model="deleteModal.isActive"
      :title="deleteModal.title"
      @ok="removeLink"
      @hidden="handleCancel(deleteModal.user)"
    >
      <p>
        Are you sure you want to remove the user with email
        <span v-if="deleteModal.user"> {{ deleteModal.user.email }}</span> from
        <span v-if="organisation"> {{ organisation.name }} </span> ?
      </p>
      <p v-if="deleteModal.note"><b>Note: </b> {{ deleteModal.note }}</p>
    </b-modal>

    <!-- ORGANISATION USERS -->
    <b-card class="my-4 reducer-card reducer-box-shadow">
      <div>
        <h4 class="d-inline-block">Users</h4>
        <p class="m-0 float-right" id="eligibleSpinner" v-if="searchingEligibleUsers">
          Getting user connections
          <Busy class="ml-2 d-inline-block" primary :margin="0" :size="1.5" />
        </p>
        <p
          id="eligibleUsers"
          class="m-0 float-right"
          v-else-if="admin && eligibleUsers.length == 0"
        >
          <font-awesome-icon :icon="['fas', 'info-circle']" class="mr-1" />
          No users eligible to re-extract this organisation.
        </p>
      </div>

      <div v-if="users && Object.keys(users).length > 0">
        <p>
          Users become linked to an organisation when they connect Reducer to their cloud accounting
          platform organisation. Users may appear more than once if they have multiple accounts with
          Reducer.
        </p>

        <div class="w-100 row-card user" v-for="(user, key) in users" :key="key" :id="key">
          <div>
            <p class="m-0 d-inline-block">
              {{ user.email }}
            </p>

            <div class="ml-1 d-inline-block">
              <b-badge
                class="mx-1 d-inline-block"
                v-for="role in user.roles"
                :key="role.id"
                v-b-tooltip.hover
                :title="role | roleDescription"
              >
                {{ role.id }}
              </b-badge>
            </div>
          </div>

          <div class="action-buttons">
            <div
              v-if="admin && canExtract(organisation.organisationSource) && user.eligible"
              class="mr-2 d-inline-block"
            >
              <b-button
                v-if="extractingUserId && extractingUserId == key"
                disabled
                variant="primary"
                pill
                size="sm"
              >
                <Busy class="d-inline-block mr-1" light :size="1" :margin="0" /> Starting extract
              </b-button>
              <b-button
                v-else
                :disabled="user.busy == true || extractingUserId"
                @click.stop="startExtract(user)"
                variant="primary"
                pill
                size="sm"
              >
                Start extract
              </b-button>
            </div>

            <b-button
              v-if="user.busy != true"
              :disabled="extractingUserId"
              @click.stop="removeLinkConfirmation(user)"
              variant="danger"
              pill
              size="sm"
            >
              Delete
            </b-button>
            <b-button v-else disabled variant="danger" pill size="sm">
              <Busy class="px-2" light :size="1.1" :margin="0" />
            </b-button>
          </div>
        </div>
      </div>
      <p v-else>
        We couldn't find any users linked to this organisation. Users become linked to an
        organisation when they connect Reducer to their cloud accounting platform organisation.
      </p>
    </b-card>

    <!-- SOURCE USERS -->
    <b-card class="reducer-card reducer-box-shadow" v-if="admin">
      <div>
        <h4 class="d-inline-block">Source Users</h4>

        <b-dropdown
          v-if="organisation && organisation.sourceUsers && emailDomains"
          variant="outline-secondary"
          text="Copy emails by domain"
          id="email-dropdown"
          class="d-inline-block float-right"
        >
          <b-dropdown-item
            v-for="(fullEmail, emailDomain) in emailDomains"
            :key="emailDomain"
            v-clipboard:copy="fullEmail"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError"
            >{{ emailDomain }}</b-dropdown-item
          >
        </b-dropdown>
      </div>

      <div v-if="organisation && organisation.sourceUsers">
        <p>
          Users linked to the organisation in the source accounting platform. These users may or may
          not be Reducer users
        </p>

        <!-- PAGINATION -->
        <div id="pagination-div" class="mx-2" v-if="organisation.sourceUsers.length > 6">
          <p class="m-0 user-number">{{ organisation.sourceUsers.length }} Source User(s)</p>
          <b-pagination
            v-model="suCurrentPage"
            :per-page="perPage"
            :total-rows="organisation.sourceUsers.length"
            small
          >
          </b-pagination>
        </div>

        <!-- LIST -->
        <div class="row-card" v-for="user in slicedSourceUsers" :key="user.email" :id="user.email">
          <p class="m-0 mr-4">{{ user.forename }} {{ user.surname }}</p>
          <p class="m-0">
            <b>{{ user.email }}</b>
          </p>
        </div>
      </div>
      <p v-else>
        We could not find any users linked to this organisation in the source accounting platform.
      </p>
    </b-card>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#organisation-details-users {
  #eligibleUsers {
    font-size: 13px;
  }

  #eligibleSpinner {
    color: $color-blue-darker1;
  }

  #email-dropdown {
    @media screen and (max-width: 575px) {
      width: 100% !important;
      margin-bottom: 10px;
    }
  }

  #pagination-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    & > .user-number {
      font-size: 14px;
      color: $color-blue-darker1;
      font-weight: bold;
    }
  }

  .row-card {
    margin: 0.5rem 0.5rem 0.5rem 0.5rem;
    padding: 0.75rem 1rem 0.75rem 1rem;
    border-radius: 5px;
    align-items: center;
    background-color: $color-grey-lighter5;
    &.user {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        display: block;
        .action-buttons {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import Busy from "@/components/Busy";

export default {
  name: "OrganisationDetailsUsers",
  components: { Busy },
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    fullMember: {
      type: Boolean,
      default: false
    },
    organisation: {
      type: Object,
      default: null
    },
    users: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      deleteModal: {
        title: "Remove user from organisation",
        isActive: false
      },
      emailDomains: {},
      eligibleUsers: [],
      searchingEligibleUsers: false,
      extractingUserId: null,

      //pagination
      suCurrentPage: 1,
      perPage: 30
    };
  },
  computed: {
    /* I love slicing users #dark_thoughts */
    slicedSourceUsers: function () {
      return this.organisation.sourceUsers.slice(
        (this.suCurrentPage - 1) * this.perPage,
        this.suCurrentPage * this.perPage
      );
    }
  },
  methods: {
    //SOURCE USERS
    getEmailDomains(users) {
      this.sortSourceUsersByDomain(users);
      users.forEach((user) => {
        if (user.email.indexOf("@") > -1) {
          if (this.emailDomains[user.email.substring(user.email.lastIndexOf("@") + 1)]) {
            this.emailDomains[user.email.substring(user.email.lastIndexOf("@") + 1)] +=
              "\n" + user.email;
          } else {
            this.emailDomains[user.email.substring(user.email.lastIndexOf("@") + 1)] = user.email;
          }
        }
      });
    },

    sortSourceUsersByDomain(sourceUsers) {
      this.organisation.sourceUsers = sourceUsers.sort(function (a, b) {
        if (a.email.indexOf("@") > -1 && b.email.indexOf("@") > -1) {
          var domA = a.email.split("@")[1];
          var domB = b.email.split("@")[1];

          if (domA > domB) {
            return 1;
          } else if (domA < domB) {
            return -1;
          }
          return 0;
        } else {
          return 0;
        }
      });
    },

    //REMOVE USERS
    removeLinkConfirmation(item) {
      this.users[item.userId].busy = true;
      this.deleteModal.isActive = true;
      this.deleteModal.user = item;
      this.deleteModal.note =
        Object.keys(this.users).length == 1
          ? "It looks like this is the last user linked to the organisation, if you are that user you will loose access to the organisation data."
          : null;
    },

    async removeLink(evt) {
      evt.preventDefault();
      try {
        let client = await ApiHelper.http();
        var response = await client.delete(
          `${ApiHelper.endPoint()}users/${this.deleteModal.user.userId}/organisations/${
            this.organisation.organisationId
          }`
        );
        if (response.data.status === "success") {
          this.deleteModal.isActive = false;
          this.showMessage("User successfully removed from the organisation", "success");

          let usersAux = Object.assign({}, this.users);
          delete usersAux[this.deleteModal.user.userId];
          this.$emit("reloadUsers", usersAux);
        } else {
          this.showMessage(
            "Sorry, there was a problem with that request. The user was not removed from the organisation.",
            "warning"
          );
          this.handleCancel(this.deleteModal.user);
        }
      } catch {
        this.showMessage(
          "Sorry, there was a problem with that request. The user was not removed from the organisation.",
          "warning"
        );
        this.handleCancel(this.deleteModal.user);
      }
    },

    //GET USERS THAT CAN DO AN EXTRACT
    async getEligibleUsers() {
      this.searchingEligibleUsers = true;

      try {
        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}extracts/users?organisationId=${this.organisation.organisationId}`
        );

        if (response.data.status === "success") {
          this.eligibleUsers = response.data.users;
          if (this.eligibleUsers && this.eligibleUsers.length > 0) {
            this.eligibleUsers.forEach((eligible) => {
              this.users[eligible.userId].eligible = true;
            });
          }
        } else {
          this.showMessage("There has been an error while getting extract users", "warning");
          console.error("Error getting users: ", response);
        }
      } catch (e) {
        this.showMessage("There has been an error while getting extract users", "warning");
        console.error("There has been an error while getting extract users: ", e);
      } finally {
        this.searchingEligibleUsers = false;
      }
    },

    async startExtract(item) {
      let platform = this.organisation.organisationSource.toLowerCase();

      this.extractingUserId = item.userId;
      try {
        let client = await ApiHelper.http();
        var response = await client.post(
          `${ApiHelper.endPoint()}extracts/start?userId=${
            item.userId
          }&platform=${platform}&organisationId=${this.organisation.organisationId}`
        );
        if (response.data.status === "success") {
          this.showMessage("The extract has been started", "success");
        } else {
          this.showMessage("The extract could not be started", "warning");
          console.error("The extract could not be started: ", response);
        }
      } catch (err) {
        this.showMessage("The extract could not be started", "warning");
        console.error("The extract could not be started: ", err);
      } finally {
        this.extractingUserId = null;
      }
    },

    canExtract(source) {
      return source == "Xero" || source == "Quickbooks";
    },

    //HELPER FUNCTIONS
    tabActivated() {
      if (this.organisation) {
        if (this.organisation.sourceUsers && Object.keys(this.emailDomains).length == 0) {
          this.getEmailDomains(this.organisation.sourceUsers);
        }

        if (this.admin && this.eligibleUsers.length == 0) {
          this.getEligibleUsers();
        }
      }
    },

    handleCancel(item) {
      if (this.users && this.users[item.userId]) {
        this.users[item.userId].busy = false;
        this.$emit("reloadUsers", this.users);
      }
    },

    showMessage(message, type) {
      EventBus.$emit("show-toast", { message: message, variant: type });
    },

    copySuccess() {
      this.showMessage("Emails copied successfully.", "success");
    },

    copyError() {
      this.showMessage("Couldn't copy URL.", "warning");
    }
  }
};
</script>