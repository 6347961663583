<template>
  <div id="organisation-details-forms">
    <div v-if="loadingOrganisation">
      <Busy primary text="Loading organisation" />
    </div>
    <div v-else-if="organisation">
      <p v-if="organisation.organisationSource != 'Reducer'">
        Organisation details are synced from your accounting platform and cannot be edited in
        Reducer. If you would like to update any details, make the changes in your accounting
        platform and they will be synced with Reducer next time you connect.
      </p>

      <!-- GENERAL DETAILS -->
      <div id="details-form" class="mx-1">
        <div class="pr-3">
          <b-form-group
            label="Organisation Id"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.organisationId"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Name"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.name"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Legal Name"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.legalName"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Industry"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.industryType"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Type"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.organisationType"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Source Platform"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-input-group class="source-logo">
              <template #append>
                <b-img
                  width="30px"
                  height="30px"
                  class="my-auto mx-1"
                  v-if="organisation.organisationSource == 'QuickBooks'"
                  rounded="circle"
                  :src="require('@/assets/images/qb.png')"
                />
                <b-img
                  width="30px"
                  height="30px"
                  class="my-auto mx-1"
                  v-else-if="organisation.organisationSource == 'Xero'"
                  rounded="circle"
                  :src="require('@/assets/images/xero.png')"
                />
                <b-img
                  width="30px"
                  height="30px"
                  class="my-auto mx-1"
                  v-else-if="organisation.organisationSource == 'ArmstrongWatson'"
                  rounded="circle"
                  :src="require('@/assets/images/armstrongwatson.png')"
                />
                <b-img
                  width="30px"
                  height="30px"
                  class="my-auto mx-1"
                  v-else-if="organisation.organisationSource == 'Reducer'"
                  rounded="circle"
                  :src="require('@/assets/images/reducer_pink.png')"
                />
              </template>

              <b-form-input disabled v-model="organisation.organisationSource"></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group
            label="Shortcode"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.shortCode"></b-form-input>
          </b-form-group>
        </div>

        <div class="pl-3">
          <b-form-group
            label="Created in Source"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-datepicker
              disabled
              v-model="organisation.sourceCreatedDateUTC"
            ></b-form-datepicker>
          </b-form-group>

          <b-form-group
            label="Created in Reducer"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-datepicker disabled v-model="organisation.createdDate"></b-form-datepicker>
          </b-form-group>

          <b-form-group
            label="Country Code"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.countryCode"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Base Currency"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.baseCurrency"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Pays Tax"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-button-group>
              <b-button
                :variant="organisation.paysTax ? 'primary' : 'outline-secondary'"
                @click="organisation.paysTax = true"
                disabled
                >YES</b-button
              >
              <b-button
                :variant="organisation.paysTax ? 'outline-secondary' : 'primary'"
                @click="organisation.paysTax = false"
                disabled
                >NO</b-button
              >
            </b-button-group>
          </b-form-group>

          <b-form-group
            label="Tax Number"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <b-form-input disabled v-model="organisation.taxNumber"></b-form-input>
          </b-form-group>

          <b-form-group
            label="Financial Year End"
            label-cols="12"
            label-cols-sm="4"
            label-cols-md="3"
            label-cols-lg="4"
          >
            <div class="d-flex">
              <b-input-group class="mr-2" append="Day">
                <b-form-input
                  disabled
                  v-model="organisation.financialYearEndDay"
                  placeholder="Day"
                ></b-form-input>
              </b-input-group>

              <b-input-group append="Month">
                <b-form-input
                  disabled
                  v-model="organisation.financialYearEndMonth"
                  placeholder="Month"
                ></b-form-input>
              </b-input-group>
            </div>
          </b-form-group>
        </div>
      </div>

      <!-- Contact Information -->
      <h4 class="mt-3">Contact Information</h4>
      <hr />
      <div
        v-if="
          (organisation.addresses && organisation.addresses.length > 0) ||
          (organisation.phoneNumbers && organisation.phoneNumbers.length > 0)
        "
      >
        <!-- ADDRESSES -->
        <div v-if="organisation.addresses && organisation.addresses.length > 0">
          <h5 class="mb-3 mx-2">Addresses</h5>

          <p
            class="simple-row mx-2 mb-2"
            v-for="address in organisation.addresses"
            :key="address.address1 + address.type"
          >
            {{ address.type }} ADDRESS : <b class="ml-2"> {{ formatAddress(address) }}</b>
          </p>
        </div>

        <!-- PHONE NUMBERS -->
        <div v-if="organisation.phoneNumbers && organisation.phoneNumbers.length > 0">
          <h5 class="mx-2 my-3 d-inline-block">Phone Numbers:</h5>

          <b-badge
            class="simple-row p-2 mx-2"
            v-for="number in organisation.phoneNumbers"
            :key="number"
          >
            <b>{{ number }}</b>
          </b-badge>
        </div>
      </div>
      <div v-else>
        <p>We could not find any contact information.</p>
      </div>

      <!-- BANK ACCOUNTS -->
      <div v-if="admin">
        <h4 class="mt-3">Bank Accounts</h4>
        <hr />

        <div
          v-if="organisation.bankAccounts && organisation.bankAccounts.length > 0"
          id="bank-accounts"
        >
          <b-card
            class="reducer-card reducer-box-shadow my-2 mx-2"
            v-for="bankAccount in organisation.bankAccounts"
            :key="bankAccount.accountNumber + bankAccount.sortCode + bankAccount.name"
          >
            <h5 class="mb-3 ml-1">
              <font-awesome-icon class="mx-2" :icon="['far', 'credit-card']" />
              {{ bankAccount.name }}
            </h5>
            <div class="card-row my-2">
              <p>Sort Code</p>
              <p class="float-right">
                <b> {{ formatSortCode(bankAccount.sortCode) }} </b>
              </p>
            </div>

            <div class="card-row my-2">
              <p>Account Number</p>
              <p class="float-right">
                <b> {{ bankAccount.accountNumber }} </b>
              </p>
            </div>

            <div class="card-row description my-2">
              <p>Description</p>
              <p>
                <b>
                  {{ bankAccount.description }}
                </b>
              </p>
            </div>
          </b-card>
        </div>
        <div v-else>
          <p>No bank accounts found for this organisation.</p>
        </div>
      </div>
    </div>
    <div v-else>
      <p>Sorry, there was a problem fetching the details for that Organisation.</p>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#organisation-details-forms {
  #details-form {
    display: flex;
    flex-wrap: wrap;
    & > div {
      width: 50%;
      @media screen and (max-width: 991px) {
        width: 100%;
        padding: 0 !important;
      }
    }

    .source-logo > .input-group-append {
      border: 1px solid $color-grey-lighter2;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  #bank-accounts {
    display: flex;
    flex-wrap: wrap;

    .card-body {
      padding: 20px 10px 10px 10px;
    }

    & > div {
      width: 48%;
      text-transform: uppercase;

      @media screen and (max-width: 991px) {
        width: 100%;
      }

      .card-row {
        display: flex;
        justify-content: space-between;
        background-color: $color-grey-lighter4;

        & > p {
          margin: 0 !important;
          font-size: 14px;
          padding: 12px 10px 12px 10px;
        }
      }

      .description {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }

  .simple-row {
    background-color: $color-grey-lighter4;
    font-size: 14px;
    padding: 12px;
    color: $color-font-para;
  }
}
</style>

<script>
import Busy from "@/components/Busy";
import FormatHelper from "@/helper/formathelper";
import AddressHelper from "@/helper/addresshelper";

export default {
  name: "OrganisationDetailsForms",
  components: { Busy },
  props: {
    admin: {
      type: Boolean,
      default: false
    },
    organisation: {
      type: Object,
      default: null
    },
    loadingOrganisation: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    formatSortCode: FormatHelper.formatSortCode,
    formatAddress: AddressHelper.oneLine
  }
};
</script>