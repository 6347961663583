<template>
  <div id="organisation-details">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container id="organisation-details-container">
      <h2 id="organisation-title">
        {{ organisation && organisation.name ? organisation.name : "Organisation Details" }}
      </h2>
      <b-tabs id="organisation-tabbing" class="mt-3" content-class="mx-3 mt-4">
        <b-tab title="Details" active key="1">
          <OrganisationDetailsForms
            :organisation="organisation"
            :admin="admin"
            :loadingOrganisation="loadingOrganisation"
          />
        </b-tab>

        <b-tab
          :disabled="loadingOrganisation"
          key="2"
          v-if="admin || fullMember"
          title="Users"
          @click="$refs.organisationDetailsUsers.tabActivated()"
        >
          <OrganisationDetailsUsers
            ref="organisationDetailsUsers"
            :organisation="organisation"
            :admin="admin"
            :fullMember="fullMember"
            :users="users"
            @reloadUsers="reloadUsers"
          />
        </b-tab>

        <b-tab
          title="Actions"
          :disabled="loadingOrganisation || !organisation"
          key="3"
          v-if="admin || fullMember"
        >
          <b-card class="reducer-card reducer-box-shadow">
            <h4>Delete Organisation</h4>
            <p>
              Deleting data will remove all accountancy data held by Reducer for this organisation,
              such as invoices and bank reports. The data will no longer be available to any other
              users of the organisation.
            </p>
            <p v-if="admin">Deleting this organisation will remove it for all users.</p>
            <div v-else>
              <p>
                If you are the only user of an organisation, deleting the organisation will remove
                all accountancy data held by Reducer for the organisation, as well removing the
                organisation details.
              </p>
              <p>
                If there are other users of the organisation, deleting the organisation will unlink
                you from the organisation but the organisation data and details will remain active
                for the other users. If there are other users of an organisation you can delete the
                organisation data before unlinking yourself from that organisation.
              </p>
              <p>
                If you think the organisation should be removed even if there are other users,
                please contact us.
              </p>
            </div>

            <div class="float-right" v-if="!deletingOrganisation">
              <b-button pill @click.stop="removeOrganisationVariant(false)">
                <font-awesome-icon class="mr-1" :icon="['fas', 'trash']" />Delete Data </b-button
              >&nbsp;
              <b-button pill @click.stop="removeOrganisationVariant(true)" variant="danger">
                <font-awesome-icon class="mr-1" :icon="['fas', 'exclamation-circle']" />Delete
                Organisation
              </b-button>
            </div>
            <Busy
              class="float-right"
              :margin="0"
              :size="1.5"
              v-else
              primary
              :text="
                deleteModal.allData ? 'Deleting the organisation' : 'Deleting organisation data'
              "
            />
          </b-card>
          <b-modal :title="deleteModal.title" v-model="deleteModal.isActive" @ok="handleOk">
            <span v-html="deleteModal.content" />
          </b-modal>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

#organisation-details {
  margin-bottom: 120px;

  #organisation-details-container {
    max-width: 1500px;

    & > h2 {
      border-bottom: none;
      text-align: center;
    }

    .reducer-card {
      border-radius: 10px;
    }

    .organisations-actions-dropdown {
      float: right;

      @media screen and (max-width: 767px) {
        display: block;
        float: none;
        text-align: center;
        margin: 10px 0 10px 0;
      }
    }

    #organisation-tabbing .nav.nav-tabs {
      border: none;

      .nav-item {
        padding: 0 10px 0 10px;

        .nav-link {
          border: none;
          border-bottom: 4px solid;
          border-color: $color-grey-lighter2;
          color: $color-font-headings;
        }

        .nav-link:hover {
          border-color: $color-font-para;
        }

        .nav-link.active {
          border-color: $color-pink-main;
        }

        @media screen and (max-width: 575px) {
          flex-grow: 1 !important;
          .nav-link {
            text-align: center;
          }
        }

        @media screen and (max-width: 767px) {
          padding: 0 5px 0 5px;
        }
      }

      @media screen and (max-width: 767px) {
        justify-content: center !important;
      }
    }

    #organisation-title {
      margin: 0 !important;
      padding-bottom: 0.5rem;
      text-align: left;
      display: inline-block;

      @media screen and (max-width: 767px) {
        text-align: center !important;
        display: block;
        width: 100;
      }
    }
  }
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import CognitoAuth from "@/cognito/cognitoauth";
import TokenHelper from "@/cognito/tokenhelper";
import RoleHelper from "@/helper/rolehelper";
import Busy from "@/components/Busy";
import OrganisationDetailsForms from "@/components/OrganisationDetailsForms";
import OrganisationDetailsUsers from "@/components/OrganisationDetailsUsers";
import FormatHelper from "@/helper/formathelper";

export default {
  name: "OrganisationDetails",
  components: { Busy, OrganisationDetailsForms, OrganisationDetailsUsers },
  data() {
    return {
      title: "Organisation Details",
      loadingOrganisation: false,
      deletingOrganisation: false,

      organisation: {},
      users: {},
      deleteModal: {
        title: "",
        content: "",
        isActive: false,
        allData: true
      },
      admin: false,
      fullMember: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    formatSortCode: FormatHelper.formatSortCode,

    showMessage(message, variant) {
      EventBus.$emit("show-toast", { message: message, variant: variant });
    },

    reloadUsers(users) {
      this.users = Object.assign({}, users);
    },

    async fetchData() {
      this.loadingOrganisation = true;
      try {
        this.admin = await CognitoAuth.isCurrentUserAdmin();
        const organisationId = this.$route.params.organisationId;

        let client = await ApiHelper.http();
        var response = await client.get(
          `${ApiHelper.endPoint()}users?includeLinks=true&organisationId=${organisationId}`
        );

        if (response.data.status == "success") {
          this.organisation = response.data.organisation;
          //SETUP USERS
          this.users = {};
          if (
            typeof response.data.users != "undefined" &&
            response.data.users &&
            response.data.users.length > 0
          ) {
            response.data.users.forEach((user) => {
              this.users[user.userId] = user;
            });
          }

          //SETUP USER ROLES AND MEMBERSHIP
          var links = response.data.links;
          if (typeof links != "undefined" && links && links.length > 0) {
            var token = await CognitoAuth.getCurrentUserAccessToken();
            var currentUserId = TokenHelper.parseJwtToken(token).sub;

            links.forEach((link) => {
              if (this.users[link.userId]) {
                this.users[link.userId].roles = link.roles;
              }

              if (currentUserId == link.userId && link.roles && link.roles.length > 0) {
                link.roles.forEach((role) => {
                  if (role.id === RoleHelper.FULL_MEMBER_ID) {
                    this.fullMember = true;
                  }
                });
              }
            });
          }

          //SETUP BANK ACCOUNTS
          if (this.organisation) {
            this.sanitizeBankAccounts(this.organisation.bankAccounts);
          }
        } else {
          this.showMessage(
            "Sorry, there was a problem fetching the details for that Organisation.",
            "warning"
          );
        }
      } catch {
        this.showMessage(
          "Sorry, there was a problem fetching the details for that Organisation.",
          "warning"
        );
      } finally {
        this.loadingOrganisation = false;
      }
    },

    isValidAcc(acc) {
      if (acc && acc != "10-00-00" && acc != "00-00-00" && !acc.match(/^0+$/) && acc.length == 8) {
        return true;
      } else {
        return false;
      }
    },

    sanitizeBankAccounts(orgBankAccounts) {
      if (orgBankAccounts && orgBankAccounts.length > 0) {
        let bankAccounts = [];
        orgBankAccounts.forEach((bankAccount) => {
          if (
            this.isValidAcc(bankAccount.accountNumber) &&
            this.isValidAcc(this.formatSortCode(bankAccount.sortCode))
          ) {
            bankAccounts.push(bankAccount);
          }
        });

        this.organisation.bankAccounts = [...bankAccounts];
      }
    },

    //DELETE ORGANSIATION MODAL
    removeOrganisationVariant(allData) {
      if (allData) {
        this.deleteModal.title = "Delete organisation";
        this.deleteModal.content = `<p>Are you sure you want to remove ${this.organisation.name}?</p>`;
      } else {
        this.deleteModal.title = "Delete all organisation data";
        this.deleteModal.content = `<p>Are you sure you want to remove ${this.organisation.name} organisation data?</p>`;
      }

      this.deleteModal.isActive = true;
      this.deleteModal.allData = allData;
    },

    handleOk() {
      if (this.deleteModal.allData) {
        this.confirmRemoveOrganisation();
      } else {
        this.confirmRemoveOrganisationData();
      }
    },

    //DELETE ORGANISATION ACTION
    async confirmRemoveOrganisation() {
      try {
        this.deletingOrganisation = true;
        let client = await ApiHelper.http();
        var response = await client.delete(
          `${ApiHelper.endPoint()}organisations/${this.organisation.organisationId}`
        );

        if (response.data.status === "success") {
          this.$router.push({ name: "dashboard" });
        } else {
          this.showMessage(
            "Sorry, there was a problem with that request. The organisation was not removed.",
            "warning"
          );
        }
      } catch {
        this.showMessage(
          "Sorry, there was a problem with that request. The organisation was not removed.",
          "warning"
        );
      } finally {
        this.deletingOrganisation = false;
      }
    },
    async confirmRemoveOrganisationData() {
      try {
        this.deletingOrganisation = true;
        let client = await ApiHelper.http();
        var response = await client.delete(
          `${ApiHelper.endPoint()}organisations/${this.organisation.organisationId}/data`
        );

        if (response.data.status === "success") {
          this.showMessage(
            "Request accepted: we are deleting the data in the background",
            "success"
          );
        } else {
          this.showMessage(
            "Sorry, there was a problem with that request. No data was deleted.",
            "warning"
          );
        }
      } catch {
        this.showMessage(
          "Sorry, there was a problem with that request. No data was deleted.",
          "warning"
        );
      } finally {
        this.deletingOrganisation = false;
      }
    }
  }
};
</script>